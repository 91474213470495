import React from 'react';
import { Grid, Typography, Box, Link, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { FaCheckCircle, FaHandsHelping, FaRobot, FaWallet } from 'react-icons/fa';

// Styled container with some alignment improvements
const StyledBox = styled(Box)({
  backgroundColor: '#1c1c1c',
  color: '#fff',
  padding: '2rem',
  borderRadius: '10px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
  textAlign: 'center',
  marginTop: '2rem',
});

// Styled Typography for achievements
const AchievementText = styled(Typography)({
  color: '#b0b0b0',
  marginBottom: '8px',
  fontSize: '1.1rem',
});

// Main Achievements Component
const Achievements = () => {
  return (
    <StyledBox id="achievements">
      <Typography variant="h4" gutterBottom sx={{ color: '#ffcc00', fontWeight: 'bold', mb: 4 }}>
        Notable Achievements
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {/* Verified with Subber & Atlas */}
        <Grid item xs={12} sm={6} md={3}>
          <Box>
            <IconButton color="primary" sx={{ fontSize: '3rem' }}>
              <FaCheckCircle style={{ color: '#ffcc00' }} />
            </IconButton>
            <AchievementText>
              Verified with <Link href="https://www.subber.xyz/mindao/" target="_blank" sx={{ color: '#ffcc00', fontWeight: 'bold' }}>Subber</Link> & <Link href="https://atlas3.io/project/mind" target="_blank" sx={{ color: '#ffcc00', fontWeight: 'bold' }}>Atlas</Link>
            </AchievementText>
          </Box>
        </Grid>

        {/* Donation to Shrouds derug */}
        <Grid item xs={12} sm={6} md={3}>
          <Box>
            <IconButton color="primary" sx={{ fontSize: '3rem' }}>
              <FaHandsHelping style={{ color: '#ffcc00' }} />
            </IconButton>
            <AchievementText>
              Collective donation to the reminting/derug of <Link href="https://www.tensor.trade/trade/v2_shrouds" target="_blank" sx={{ color: '#ffcc00', fontWeight: 'bold' }}>Shrouds</Link>
            </AchievementText>
          </Box>
        </Grid>

        {/* TG Bot and NFT verification */}
        <Grid item xs={12} sm={6} md={3}>
          <Box>
            <IconButton color="primary" sx={{ fontSize: '3rem' }}>
              <FaRobot style={{ color: '#ffcc00' }} />
            </IconButton>
            <AchievementText>
              Created TG Bot and NFT verification tool
            </AchievementText>
          </Box>
        </Grid>

        {/* Realms multisig treasury */}
        <Grid item xs={12} sm={6} md={3}>
          <Box>
            <IconButton color="primary" sx={{ fontSize: '3rem' }}>
              <FaWallet style={{ color: '#ffcc00' }} />
            </IconButton>
            <AchievementText>
              Created our <Link href="https://app.realms.today/dao/FyojLqtqA85BTYim8SGzBQECqAjuZ5JN2UKuP9skYrFB" target="_blank" sx={{ color: '#ffcc00', fontWeight: 'bold' }}>Realms multisig treasury</Link>
            </AchievementText>
          </Box>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default Achievements;

