import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Infographic from './components/Infographic';
import About from './components/About';
import Footer from './components/Footer';
import Members from './components/Members';
import Achievements from './components/Achievements';
import './App.css';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#ffcc00',  // Custom primary color
    },
    secondary: {
      main: '#00bcd4',  // Custom secondary color
    },
    background: {
      default: '#121212',  // Dark background
    },
    text: {
      primary: '#ffffff',  // White text by default
      secondary: '#b0b0b0',  // Light gray for secondary text
    },
  },
  typography: {
    fontFamily: 'Montserrat, Arial, sans-serif', // Custom font
    h4: {
      fontWeight: 'bold',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="app-container">
        <div className="content-wrapper">
          <About />
          <Infographic />
	  <Achievements />
          <Members />
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;

