import React from 'react';
import { Grid, Typography, Box, Card, CardContent, CardMedia } from '@mui/material';
import { styled } from '@mui/system';

// Import logos
import sadbotsLogo from '../assets/sadbots.webp';
import mnk3ysLogo from '../assets/mnk3ys.webp';
import wifhoodiesLogo from '../assets/wifhoodie.webp';
import g00bsLogo from '../assets/g00bs.webp';
import portalBeastsLogo from '../assets/portalbeasts.webp';

// Styled card container with improved shadow and alignment
const StyledCard = styled(Card)({
  backgroundColor: '#1c1c1c',
  color: '#fff',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.6), 0px 6px 12px rgba(255, 255, 0, 0.3)', // Subtle shadow with a yellow hint
  textAlign: 'center',
  borderRadius: '10px',
  width: '160px', // Fixed width for uniform size
  height: '240px', // Fixed height for uniform size
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between', // Ensures content stays aligned
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.7), 0px 10px 20px rgba(255, 255, 0, 0.5)', // More pronounced shadow on hover
  },
});

// Adjust Sadbots-specific padding and logo position
const SadbotsCardContent = styled(CardContent)({
  paddingBottom: '0px !important', // Remove bottom padding with !important to override MUI styles
});

const Infographic = () => {
  return (
    <Box id="infographic" sx={{ py: 8, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#ffcc00', fontWeight: 'bold', mb: 4 }}>
        MIND Collective Holdings
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {/* MNK3Ys */}
        <Grid item>
          <StyledCard>
            <CardMedia
              component="img"
              height="80"
              image={mnk3ysLogo}
              alt="MNK3Ys Logo"
              sx={{ objectFit: 'contain', margin: 'auto', marginTop: '20px' }}
            />
            <CardContent sx={{ paddingBottom: '16px' }}>
              <Typography variant="body2" sx={{ color: '#b0b0b0' }}>
                MNK3Ys
              </Typography>
              <Typography variant="h6" sx={{ color: '#ffcc00', fontWeight: 'bold', fontSize: '1rem' }}>
                20%+
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Portal Beasts */}
        <Grid item>
          <StyledCard>
            <CardMedia
              component="img"
              height="80"
              image={portalBeastsLogo}
              alt="Portal Beasts Logo"
              sx={{ objectFit: 'contain', margin: 'auto', marginTop: '20px' }}
            />
            <CardContent sx={{ paddingBottom: '16px' }}>
              <Typography variant="body2" sx={{ color: '#b0b0b0' }}>
                Portal Beasts
              </Typography>
              <Typography variant="h6" sx={{ color: '#ffcc00', fontWeight: 'bold', fontSize: '1rem' }}>
                15%+
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Sadbots */}
        <Grid item>
          <StyledCard>
            <CardMedia
              component="img"
              height="80"
              image={sadbotsLogo}
              alt="Sadbots Logo"
              sx={{ objectFit: 'contain', margin: 'auto', marginTop: '30px' }} // Adjusted margin to move the logo down
            />
            <SadbotsCardContent>
              <Typography variant="body2" sx={{ color: '#b0b0b0' }}>
                Sadbots
              </Typography>
              <Typography variant="h6" sx={{ color: '#ffcc00', fontWeight: 'bold', fontSize: '1rem' }}>
                15%+
              </Typography>
            </SadbotsCardContent>
          </StyledCard>
        </Grid>

        {/* G00bs */}
        <Grid item>
          <StyledCard>
            <CardMedia
              component="img"
              height="80"
              image={g00bsLogo}
              alt="G00bs Logo"
              sx={{ objectFit: 'contain', margin: 'auto', marginTop: '20px' }}
            />
            <CardContent sx={{ paddingBottom: '16px' }}>
              <Typography variant="body2" sx={{ color: '#b0b0b0' }}>
                G00bs
              </Typography>
              <Typography variant="h6" sx={{ color: '#ffcc00', fontWeight: 'bold', fontSize: '1rem' }}>
                10%+
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Wifhoodies */}
        <Grid item>
          <StyledCard>
            <CardMedia
              component="img"
              height="80"
              image={wifhoodiesLogo}
              alt="Wifhoodies Logo"
              sx={{ objectFit: 'contain', margin: 'auto', marginTop: '20px' }}
            />
            <CardContent sx={{ paddingBottom: '16px' }}>
              <Typography variant="body2" sx={{ color: '#b0b0b0' }}>
                Wifhoodies
              </Typography>
              <Typography variant="h6" sx={{ color: '#ffcc00', fontWeight: 'bold', fontSize: '1rem' }}>
                10%+
              </Typography>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Infographic;

