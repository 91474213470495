import React from 'react';

const Footer = () => {
  return (
    <footer className="py-4 text-center text-lightgray">
      <p>© 2024 MIND. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;

