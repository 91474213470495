import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Card, CardContent, CardMedia, Link, Button } from '@mui/material';
import { styled } from '@mui/system';
import TwitterIcon from '@mui/icons-material/Twitter';

// Import profile pictures
import primPic from '../assets/prim.webp';
import techguytyPic from '../assets/techguyty.webp';
import brooksPic from '../assets/brooks.webp';
import dashPic from '../assets/dash.webp';
import kingpinPic from '../assets/kingpin.webp';
import cheeseheadPic from '../assets/cheesehead.webp';
import kaiPic from '../assets/kai.webp';
import bannerPic from '../assets/banner.webp';
import banner2Pic from '../assets/banner2.webp';
import aldsPic from '../assets/alds.webp';
import jackiePic from '../assets/jackie.webp';
import vkgPic from '../assets/vkg.webp';
import g00b1Pic from '../assets/goob1.webp';
import subPic from '../assets/submarine.webp';
import hermPic from '../assets/hermpic.webp';
import davidPic from '../assets/davidpic.webp';

// Images for the gallery
const galleryImages = [primPic, kaiPic, bannerPic, banner2Pic, g00b1Pic];

// Styled card container for member profiles
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#1c1c1c',
  color: '#fff',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.6), 0px 6px 12px rgba(255, 255, 0, 0.3)', // Subtle shadow with a yellow hint
  textAlign: 'center',
  borderRadius: '10px',
  width: '100%', // Responsive width
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between', // Ensure proper spacing
  position: 'relative', // Required to position the button at the bottom
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.7), 0px 10px 20px rgba(255, 255, 0, 0.5)', // More pronounced shadow on hover
  },
}));

// Styled button for "Follow" on X (formerly Twitter)
const FollowButton = styled(Button)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  bottom: '0', // Fix button at the bottom
  left: '0', // Ensure the button starts from the left side
  height: '10%', // Set height to 10% of the card
  color: '#000',
  backgroundColor: '#ffcc00', // Set default background to the hover color from before
  '&:hover': {
    backgroundColor: '#b0b0b0', // New hover color that complements the theme
    color: '#fff',
  },
}));

const TextCardContent = styled(CardContent)(({ theme }) => ({
  paddingBottom: '100px', // Ensure content has enough space before the button
  [theme.breakpoints.down('sm')]: {
    paddingTop: '100px', // Mobile-specific padding for text
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between', // Ensures spacing for the follow button at the bottom
  '&:last-child': {
    paddingBottom: '75px', // Override the padding-bottom for the last child
  },
}));

const ImageContainer = styled(Box)({
  height: '160px', // Fixed height for images
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '15px', // Add margin to create space between the image and name
});

const NameTypography = styled(Typography)({
  color: '#ffcc00',
  fontWeight: 'bold',
  fontSize: '1.4rem',
  marginBottom: '10px',
  minHeight: '40px', // Ensures the same space for all names
  textShadow: `
    1px 1px 0px black, 
    -1px -1px 0px black, 
    1px -1px 0px black, 
    -1px 1px 0px black
  `, // Simulates an outline by layering shadows
  '@media (max-width: 600px)': {
    fontSize: '1.2rem', // Adjust font size on smaller screens
  },
});

const Members = () => {
  const [currentImage, setCurrentImage] = useState(0);

  // Rotate gallery image every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % galleryImages.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ py: 8, px: 4 }}>
      <Grid container spacing={4}>
        {/* Left side: Mission Statement */}
        <Grid item xs={12} md={8}>
          <Box sx={{ color: '#fff' }}>
            <Typography variant="h4" gutterBottom sx={{ color: '#ffcc00', fontWeight: 'bold', mb: 4 }}>
              Our Mission
            </Typography>
            <Typography variant="body1" sx={{ color: '#b0b0b0', fontSize: '1.2rem', mb: 4 }}>
              We are a passionate community supporting builders and innovators in the decentralized world. Our DAO
              is focused on helping creators build, launch, and grow projects in the Solana ecosystem. We provide resources, guidance, and a supportive network to foster innovation and collaboration.
            </Typography>
            <Typography variant="h6" sx={{ color: '#ffcc00', fontWeight: 'bold', mb: 2 }}>
              Where We Can Help
            </Typography>
            <Typography variant="body1" sx={{ color: '#b0b0b0', fontSize: '1.1rem' }}>
              - Building decentralized applications
              <br />
              - Providing investment and funding
              <br />
              - Offering outreach and networking opportunities
              <br />
              - Community building and design
            </Typography>
          </Box>
        </Grid>

        {/* Right side: Prized Possessions Gallery */}
        <Grid item xs={12} md={4}>
          <Box sx={{ color: '#fff', textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom sx={{ color: '#ffcc00', fontWeight: 'bold', mb: 4 }}>
              Prized Possessions
            </Typography>
            <Box
              sx={{
                width: '100%',
                height: '300px',
                borderRadius: '10px',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
              }}
            >
              <CardMedia
                component="img"
                image={galleryImages[currentImage]}
                alt="Prized possession"
                sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Member cards displayed in multiple rows with 5 cards per row */}
      <Box sx={{ mt: 6 }}>
        <Grid container spacing={4}>
          {/* Prim */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <StyledCard>
              <ImageContainer>
                <CardMedia
                  component="img"
                  height="160"
                  image={primPic}
                  alt="Prim's Profile"
                  sx={{ objectFit: 'contain', margin: 'auto' }}
                />
              </ImageContainer>
              <TextCardContent>
                <NameTypography>Prim</NameTypography>
                <Typography variant="body1" sx={{ color: '#b0b0b0', fontWeight: '500', mb: 1, textAlign: 'left' }}>
                  Skills:
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px', marginTop: '5px' }}>
                    <li>Fullstack Builder</li>
                    <li>Investor</li>
                  </ul>
                </Typography>
                <Typography variant="body2" sx={{ color: '#b0b0b0', fontSize: '0.9rem' }}>
                  Currently working on{' '}
                  <a
                    href="https://www.hustlez.io"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#ffcc00', textDecoration: 'none' }}
                  >
                    www.hustlez.io
                  </a>
                </Typography>
                <Link href="https://x.com/Prim_1988" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <FollowButton variant="contained" startIcon={<TwitterIcon />}>
                    Follow
                  </FollowButton>
                </Link>
              </TextCardContent>
            </StyledCard>
          </Grid>

          {/* TechguyTy */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <StyledCard>
              <ImageContainer>
                <CardMedia
                  component="img"
                  height="160"
                  image={techguytyPic}
                  alt="TechguyTy's Profile"
                  sx={{ objectFit: 'contain', margin: 'auto' }}
                />
              </ImageContainer>
              <TextCardContent>
                <NameTypography>TechguyTy</NameTypography>
                <Typography variant="body1" sx={{ color: '#b0b0b0', fontWeight: '500', mb: 1, textAlign: 'left' }}>
                  Skills:
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px', marginTop: '5px' }}>
                    <li>Fullstack Builder</li>
                    <li>Investor</li>
                  </ul>
                </Typography>
                <Typography variant="body2" sx={{ color: '#b0b0b0', fontSize: '0.9rem' }}>
                  Currently working on{' '}
                  <a
                    href="https://www.hustlez.io"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#ffcc00', textDecoration: 'none' }}
                  >
                    www.hustlez.io
                  </a>
                </Typography>
                <Link href="https://x.com/TechGuiTy" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <FollowButton variant="contained" startIcon={<TwitterIcon />}>
                    Follow
                  </FollowButton>
                </Link>
              </TextCardContent>
            </StyledCard>
          </Grid>

          {/* Brooks */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <StyledCard>
              <ImageContainer>
                <CardMedia
                  component="img"
                  height="160"
                  image={brooksPic}
                  alt="Brooks' Profile"
                  sx={{ objectFit: 'contain', margin: 'auto' }}
                />
              </ImageContainer>
              <TextCardContent>
                <NameTypography>Brooks</NameTypography>
                <Typography variant="body1" sx={{ color: '#b0b0b0', fontWeight: '500', mb: 1, textAlign: 'left' }}>
                  Skills:
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px', marginTop: '5px' }}>
                    <li>Outreach</li>
                    <li>Networking</li>
                    <li>Finance</li>
                  </ul>
                </Typography>
                <Link href="https://x.com/broo35715" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <FollowButton variant="contained" startIcon={<TwitterIcon />}>
                    Follow
                  </FollowButton>
                </Link>
              </TextCardContent>
            </StyledCard>
          </Grid>

          {/* Dash */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <StyledCard>
              <ImageContainer>
                <CardMedia
                  component="img"
                  height="160"
                  image={dashPic}
                  alt="Dash's Profile"
                  sx={{ objectFit: 'contain', margin: 'auto' }}
                />
              </ImageContainer>
              <TextCardContent>
                <NameTypography>Dash</NameTypography>
                <Typography variant="body1" sx={{ color: '#b0b0b0', fontWeight: '500', mb: 1, textAlign: 'left' }}>
                  Skills:
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px', marginTop: '5px' }}>
                    <li>Design</li>
                    <li>Community</li>
                  </ul>
                </Typography>
                <Link href="https://x.com/_Its_Dash_" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <FollowButton variant="contained" startIcon={<TwitterIcon />}>
                    Follow
                  </FollowButton>
                </Link>
              </TextCardContent>
            </StyledCard>
          </Grid>

          {/* Kingpin */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <StyledCard>
              <ImageContainer>
                <CardMedia
                  component="img"
                  height="160"
                  image={kingpinPic}
                  alt="Kingpin's Profile"
                  sx={{ objectFit: 'contain', margin: 'auto' }}
                />
              </ImageContainer>
              <TextCardContent>
                <NameTypography>Kingpin</NameTypography>
                <Link href="https://x.com/Krypt0Kingpin" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <FollowButton variant="contained" startIcon={<TwitterIcon />}>
                    Follow
                  </FollowButton>
                </Link>
              </TextCardContent>
            </StyledCard>
          </Grid>

          {/* SubmarineSinker */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <StyledCard>
              <ImageContainer>
                <CardMedia
                  component="img"
                  height="160"
                  image={subPic}
                  alt="Submarinesinker's Profile"
                  sx={{ objectFit: 'contain', margin: 'auto' }}
                />
              </ImageContainer>
              <TextCardContent>
                <NameTypography>SubmarineSinker</NameTypography>
              </TextCardContent>
            </StyledCard>
          </Grid>

          {/* Cheesehead */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <StyledCard>
              <ImageContainer>
                <CardMedia
                  component="img"
                  height="160"
                  image={cheeseheadPic}
                  alt="Cheesehead's Profile"
                  sx={{ objectFit: 'contain', margin: 'auto' }}
                />
              </ImageContainer>
              <TextCardContent>
                <NameTypography>Cheesehead</NameTypography>
                <Link href="https://x.com/luuk1987" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <FollowButton variant="contained" startIcon={<TwitterIcon />}>
                    Follow
                  </FollowButton>
                </Link>
              </TextCardContent>
            </StyledCard>
          </Grid>

          {/* Kai */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <StyledCard>
              <ImageContainer>
                <CardMedia
                  component="img"
                  height="160"
                  image={kaiPic}
                  alt="Kai's Profile"
                  sx={{ objectFit: 'contain', margin: 'auto' }}
                />
              </ImageContainer>
              <TextCardContent>
                <NameTypography>Kai</NameTypography>
                <Typography variant="body1" sx={{ color: '#b0b0b0', fontWeight: '500', mb: 1, textAlign: 'left' }}>
                  Skills:
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px', marginTop: '5px' }}>
                    <li>Community Hype</li>
                    <li>Investor</li>
                  </ul>
                </Typography>
                <Link href="https://x.com/MrMoneyMake1" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <FollowButton variant="contained" startIcon={<TwitterIcon />}>
                    Follow
                  </FollowButton>
                </Link>
              </TextCardContent>
            </StyledCard>
          </Grid>

          {/* VKG */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <StyledCard>
              <ImageContainer>
                <CardMedia
                  component="img"
                  height="160"
                  image={vkgPic}
                  alt="VKG's Profile"
                  sx={{ objectFit: 'contain', margin: 'auto' }}
                />
              </ImageContainer>
              <TextCardContent>
                <NameTypography>VKG</NameTypography>
                <Typography variant="body1" sx={{ color: '#b0b0b0', fontWeight: '500', mb: 1, textAlign: 'left' }}>
                  Skills:
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px', marginTop: '5px' }}>
                    <li>Fullstack Builder</li>
                  </ul>
                </Typography>
                <Link href="https://x.com/v_nayk" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <FollowButton variant="contained" startIcon={<TwitterIcon />}>
                    Follow
                  </FollowButton>
                </Link>
              </TextCardContent>
            </StyledCard>
          </Grid>

          {/* Aldsss */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <StyledCard>
              <ImageContainer>
                <CardMedia
                  component="img"
                  height="160"
                  image={aldsPic}
                  alt="Aldss's Profile"
                  sx={{ objectFit: 'contain', margin: 'auto' }}
                />
              </ImageContainer>
              <TextCardContent>
                <NameTypography>Aldsss</NameTypography>
              </TextCardContent>
            </StyledCard>
          </Grid>

          {/* Jackie */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <StyledCard>
              <ImageContainer>
                <CardMedia
                  component="img"
                  height="160"
                  image={jackiePic}
                  alt="Jackie's Profile"
                  sx={{ objectFit: 'contain', margin: 'auto' }}
                />
              </ImageContainer>
              <TextCardContent>
                <NameTypography>Jackie</NameTypography>
                <Typography variant="body1" sx={{ color: '#b0b0b0', fontWeight: '500', mb: 1, textAlign: 'left' }}>
                  Skills:
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px', marginTop: '5px' }}>
                    <li>Community Hype</li>
                    <li>Investor</li>
                  </ul>
                </Typography>
                <Link href="https://x.com/SolChadNFTs" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <FollowButton variant="contained" startIcon={<TwitterIcon />}>
                    Follow
                  </FollowButton>
                </Link>
              </TextCardContent>
            </StyledCard>
          </Grid>

          {/* Hermes */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <StyledCard>
              <ImageContainer>
                <CardMedia
                  component="img"
                  height="160"
                  image={hermPic}
                  alt="Hermes's Profile"
                  sx={{ objectFit: 'contain', margin: 'auto' }}
                />
              </ImageContainer>
              <TextCardContent>
                <NameTypography>Hermes</NameTypography>
                <Typography variant="body1" sx={{ color: '#b0b0b0', fontWeight: '500', mb: 1, textAlign: 'left' }}>
                  Skills:
                  <ul style={{ listStyleType: 'disc', marginLeft: '20px', marginTop: '5px' }}>
                    <li>Community Hype</li>
                    <li>Investor</li>
                  </ul>
                </Typography>
                <Link href="https://x.com/hermesmeta" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <FollowButton variant="contained" startIcon={<TwitterIcon />}>
                    Follow
                  </FollowButton>
                </Link>
              </TextCardContent>
            </StyledCard>
          </Grid>

          {/* MeDavid */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <StyledCard>
              <ImageContainer>
                <CardMedia
                  component="img"
                  height="160"
                  image={davidPic}
                  alt="MeDavid's Profile"
                  sx={{ objectFit: 'contain', margin: 'auto' }}
                />
              </ImageContainer>
              <TextCardContent>
                <NameTypography>MeDavid</NameTypography>
                <Link href="https://x.com/MeDavid_" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none' }}>
                  <FollowButton variant="contained" startIcon={<TwitterIcon />}>
                    Follow
                  </FollowButton>
                </Link>
              </TextCardContent>
            </StyledCard>
          </Grid>


        </Grid>
      </Box>
    </Box>
  );
};

export default Members;

